import React, { useState } from "react"
import { graphql } from "gatsby"
import { injectIntl, Link } from "gatsby-plugin-react-intl"
import { getImage } from "gatsby-plugin-image"
import options from "../../config/options"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import HeaderColor from "../components/HeaderColor"
import HeaderCover from "../components/HeaderCover"
import Footer from "../components/Footer"
import { Row, Col, notification } from "antd"
import iconLocation from "../../static/assets/images/icon-location-color.svg"
import iconPhone from "../../static/assets/images/icon-phone-color.svg"
import iconMail from "../../static/assets/images/icon-mail-color.svg"
import iconTime from "../../static/assets/images/icon-time-color.svg"
import styled from "@emotion/styled"

/*const ClientSideOnlyLazy = React.lazy(() => import("../components/ContactMap"))
const isSSR = typeof window === "undefined"*/
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const PageContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: calc(100% - 30px);
  margin: 0 auto 80px auto;
  .gb-container {
    max-width: 100%;
    margin: -50px auto 0 auto;
    padding: 1px 25px;
    background: #fff;
    border-radius: 10px;
  }

  @media only screen and (min-width: 576px) {
    max-width: calc(100% - 100px);
    .gb-container {
      margin: -50px auto 0 auto;
      padding: 10px 40px;
    }
  }
  @media only screen and (min-width: 768px) {
    max-width: 90%;
    .gb-container {
      margin: -70px auto 0 auto;
      padding: 10px 70px;
    }
  }
  @media only screen and (min-width: 992px) {
    max-width: 90%;
    .gb-container {
      margin: -60px auto 0 auto;
      padding: 10px 80px;
    }
  }
  @media only screen and (min-width: 1200px) {
    max-width: 90%;
    .gb-container {
      margin: -80px auto 0 auto;
      padding: 30px 60px;
    }
  }
  @media only screen and (min-width: 1440px) {
    max-width: 94%;
    .gb-container {
      margin: -100px auto 0 auto;
      padding: 30px 100px;
    }
  }
  @media only screen and (min-width: 1590px) {
    max-width: 1490px;
  }
`
const IntroContainer = styled.div`
  margin: 60px auto;
  text-align: center;
  .introWrapper {
    font-family: "Kanit", sans-serif;
    h2 {
      font-weight: 400;
      font-size: 38px;
      color: #181818;
      margin-bottom: 5px;
    }
    .content {
      font-weight: 400;
      font-size: 18px;
      color: #646464;
    }
  }

  @media only screen and (min-width: 768px) {
    margin: 60px 0;
    text-align: left;
  }
`
const ContactContainer = styled.div`
  .contentWrapper {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 100%;
      margin-bottom: 30px;
      font-family: "Kanit", sans-serif;
      .icon {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-left: -3px;
        img {
          min-width: 32px;
          width: 32px;
          min-height: 32px;
          height: 32px;
        }
        &.phone {
          margin-left: 0;
          padding-top: 3px;
          img {
            min-width: 28px;
            width: 28px;
            min-height: 28px;
            height: 28px;
          }
        }
      }
      .title {
        font-weight: 400;
        font-size: 20px;
        color: #181818;
        margin-top: 5px;
        margin-bottom: 5px;
      }
      .text {
        font-weight: 400;
        font-size: 16px;
        color: #646464;
        margin-bottom: 0;
        a {
          color: #646464;
          &:hover {
            color: #7497b2;
          }
        }
      }
      .map {
        font-weight: 700;
        font-size: 16px;
        color: #7497b2;
      }
    }
  }
  .formWrapper {
    .title {
      font-family: "Kanit", sans-serif;
      font-weight: 400;
      font-size: 38px;
      color: #7497b2;
      margin-bottom: 25px;
      text-align: center;
    }
    .hidden {
      display: none;
    }
    .controls {
      .formGroup {
        margin-bottom: 15px;
        select,
        textarea,
        input {
          width: 100%;
          height: 48px;
          font-family: "Kanit", sans-serif;
          font-weight: 400;
          font-size: 16px;
          color: #646464;
          padding: 10px 15px;
          background: #fff;
          border: 1px solid #ededed;
          box-shadow: 0px 4px 46px rgba(193, 193, 193, 0.08);
          border-radius: 10px;
          &:active,
          &:focus,
          &:hover {
            border: 1px solid #7497b2;
          }
        }
        textarea {
          min-height: 150px;
        }
        select {
          option {
            font-family: "Kanit", sans-serif;
            font-weight: 400;
            font-size: 16px;
            color: #646464;
          }
        }
      }
      .buttonWrapper {
        margin: 30px auto 0 auto;
        text-align: center;
        button {
          min-width: 160px;
          height: 42px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 0 20px;
          font-family: "Kanit", sans-serif;
          font-weight: 400;
          font-size: 16px;
          color: #fff;
          background-color: #7497b2;
          border: 1px solid #7497b2;
          border-radius: 5px;
          text-decoration: none;
          transition: all 0.25s ease-in-out;
          appearance: none;
          cursor: pointer;
          &:hover {
            color: #7497b2;
            background-color: #fff;
            text-decoration: none;
          }
        }
      }
    }
  }
  input[type="text"]::placeholder,
  input[type="email"]::placeholder,
  textarea::placeholder {
    color: #646464;
    opacity: 0.65;
  }

  @media only screen and (min-width: 576px) {
    .contentWrapper {
      .item {
        width: 50%;
        margin-bottom: 40px;
        padding-right: 15px;
      }
    }
  }
`

const ContactUsPage = ({ data, intl }) => {
  const [state, setState] = useState({})
  const [selectValue, setSelectValue] = useState("Info")
  const [showForm1, setShowForm1] = useState(true)
  const [showForm2, setShowForm2] = useState(false)
  const [showForm3, setShowForm3] = useState(false)
  const locale = intl && intl.locale ? intl.locale : `th`
  const { pageFields } = data
  const {
    seoTitle,
    seoDescription,
    coverImage,
    pageTitle,
    introContent,
  } = pageFields.edges[0].node
  const getCoverImage = getImage(coverImage)

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const handleSelectChange = e => {
    const val = e.target.value
    setSelectValue(val)
    if (val === "Info") {
      setShowForm1(true)
      setShowForm2(false)
      setShowForm3(false)
    } else if (val === "Complaint") {
      setShowForm1(false)
      setShowForm2(true)
      setShowForm3(false)
    } else if (val === "Purchasing") {
      setShowForm1(false)
      setShowForm2(false)
      setShowForm3(true)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (
      !state.name ||
      state.name.length === 0 ||
      !state.email ||
      state.email.length === 0 ||
      !state.phone ||
      state.phone.length === 0 ||
      !state.message ||
      state.message.length === 0
    ) {
      notification["warning"]({
        message: "Invalid fields!",
        description: "Please input all fields before submit.",
        duration: 0,
      })
      return false
    }

    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        subject: state.name + " has been submitted form.",
        ...state,
      }),
    })
      .then(() => {
        notification["success"]({
          message: "Success!",
          description: "Thank you for your submit, we will contact you soon.",
          duration: 0,
        })
      })
      .catch(error => {
        console.log("error ", error)
        notification["error"]({
          message: "Error!",
          description: "Submit form failed, please try again later.",
          duration: 0,
        })
      })
  }

  return (
    <Layout>
      <Seo lang={locale} title={seoTitle} description={seoDescription} />
      <HeaderColor />
      <HeaderCover bgImage={getCoverImage} coverText={pageTitle} />
      <PageContainer>
        <div className="gb-container">
          <IntroContainer>
            <Row>
              <Col xs={24} md={22} lg={20} xl={14} xxl={12}>
                <div className="introWrapper">
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: pageTitle,
                    }}
                  />
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html: introContent.introContent,
                    }}
                  />
                </div>
              </Col>
            </Row>
          </IntroContainer>
          <ContactContainer>
            <Row gutter={[80, 40]}>
              <Col xs={24} xl={12}>
                <div className="contentWrapper">
                  <div className="item">
                    <span className="icon">
                      <a
                        href="https://goo.gl/maps/s4kbxGdMpuQoD6hu9"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={iconLocation}
                          width="32"
                          height="32"
                          alt="Location"
                        />
                      </a>
                    </span>
                    <p className="title">
                      {intl.formatMessage({ id: "contactOffice" })}
                    </p>
                    <a
                      href="https://goo.gl/maps/s4kbxGdMpuQoD6hu9"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p
                        className="text"
                        dangerouslySetInnerHTML={{
                          __html: intl.formatMessage({
                            id: "contactOfficeLocation",
                          }),
                        }}
                      />
                      <p className="map">
                        {intl.formatMessage({ id: "textMap" })}
                      </p>
                    </a>
                  </div>
                  <div className="item">
                    <span className="icon phone">
                      <img src={iconPhone} width="28" height="28" alt="Phone" />
                    </span>
                    <p className="title">
                      {intl.formatMessage({ id: "contactOfficePhone" })}
                    </p>
                    <p className="text">
                      <a href={options.telCTA} target="_blank" rel="noreferrer">
                        Tel : {options.telText}
                      </a>
                      <br />
                      Fax : {options.faxText}
                    </p>
                  </div>
                  <div className="item">
                    <span className="icon">
                      <img src={iconMail} width="32" height="32" alt="Email" />
                    </span>
                    <p className="title">
                      {intl.formatMessage({ id: "contactEmail" })}
                    </p>
                    <p className="text">
                      {" "}
                      <Link to="/contact-us/">
                        {intl.formatMessage({ id: "navMenu4" })}
                      </Link>
                    </p>
                  </div>
                  <div className="item">
                    <span className="icon">
                      <img src={iconTime} width="32" height="32" alt="Time" />
                    </span>
                    <p className="title">
                      {intl.formatMessage({ id: "contactTime" })}
                    </p>
                    <p
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "contactTimeWorking",
                        }),
                      }}
                    />
                  </div>
                  <div className="item">
                    <span className="icon">
                      <a
                        href="https://maps.app.goo.gl/VMV3hQ4D2C84c4yq7"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={iconLocation}
                          width="32"
                          height="32"
                          alt="Location"
                        />
                      </a>
                    </span>
                    <p className="title">
                      {intl.formatMessage({ id: "contactFactory" })}
                    </p>
                    <a
                      href="https://maps.app.goo.gl/VMV3hQ4D2C84c4yq7"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p
                        className="text"
                        dangerouslySetInnerHTML={{
                          __html: intl.formatMessage({
                            id: "contactFactoryLocation",
                          }),
                        }}
                      />
                      <p className="map">
                        {intl.formatMessage({ id: "textMap" })}
                      </p>
                    </a>
                  </div>
                  <div className="item">
                    <span className="icon phone">
                      <img src={iconPhone} width="28" height="28" alt="Phone" />
                    </span>
                    <p className="title">
                      {intl.formatMessage({ id: "contactFactoryPhone" })}
                    </p>
                    <p className="text">
                      <a
                        href={options.telFactoryCTA}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Tel : {options.telFactoryText}
                      </a>
                      <br />
                      Fax : {options.faxFactoryText}
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={24} xl={12}>
                <div className="formWrapper">
                  <h3 className="title">
                    {intl.formatMessage({ id: "textContactUs" })}
                  </h3>
                  <form
                    name="ContactForm"
                    method="POST"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    id="conditional-form"
                  >
                    <div className="controls">
                      <div className="formGroup">
                        <select
                          id="condition"
                          name="condition"
                          required
                          onChange={e => handleSelectChange(e)}
                          value={selectValue}
                        >
                          <option key="info" value="Info">
                            {intl.formatMessage({ id: "contactManu1" })}
                          </option>
                          <option key="complaint" value="Complaint">
                            {intl.formatMessage({ id: "subMenu23" })}
                          </option>
                          <option key="purchasing" value="Purchasing">
                            {intl.formatMessage({ id: "contactManu2" })}
                          </option>
                        </select>
                      </div>
                    </div>
                  </form>
                  {/* ConteactForm to info@tsbsteel.com */}
                  <form
                    name="ContactFormInfo"
                    method="POST"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={handleSubmit}
                    className={showForm1 ? "" : "hidden"}
                  >
                    <input
                      type="hidden"
                      name="form-name"
                      value="ContactFormInfo"
                    />
                    <input
                      type="hidden"
                      name="subject"
                      value={state.name + " has been submitted form."}
                    />
                    <p hidden>
                      <label>
                        Don’t fill this out:
                        <input name="bot-field" onChange={handleChange} />
                      </label>
                    </p>

                    <div className="controls">
                      <div className="formGroup">
                        <input
                          id="form_name"
                          type="text"
                          name="name"
                          placeholder={intl.formatMessage({ id: "formName" })}
                          required="required"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="formGroup">
                        <input
                          id="form_email"
                          type="email"
                          name="email"
                          placeholder={intl.formatMessage({ id: "formEmail" })}
                          required="required"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="formGroup">
                        <input
                          id="form_phone"
                          type="text"
                          name="phone"
                          placeholder={intl.formatMessage({ id: "formPhone" })}
                          required="required"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="formGroup">
                        <textarea
                          id="form_message"
                          name="message"
                          placeholder={intl.formatMessage({
                            id: "formMessage",
                          })}
                          rows="4"
                          required="required"
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <div className="buttonWrapper">
                        <button type="submit">
                          {intl.formatMessage({ id: "formSubmit" })}
                        </button>
                      </div>
                    </div>
                  </form>
                  {/* ConteactForm to complaint@tsbsteel.com */}
                  <form
                    name="ContactFormComplaint"
                    method="POST"
                    data-netlify="true"
                    onSubmit={handleSubmit}
                    className={showForm2 ? "" : "hidden"}
                  >
                    <input
                      type="hidden"
                      name="form-name"
                      value="ContactComplaint"
                    />
                    <input
                      type="hidden"
                      name="subject"
                      value={state.name + " has been submitted form."}
                    />
                    <p hidden>
                      <label>
                        Don’t fill this out:
                        <input name="bot-field" onChange={handleChange} />
                      </label>
                    </p>

                    <div className="controls">
                      <div className="formGroup">
                        <input
                          id="form_name"
                          type="text"
                          name="name"
                          placeholder={intl.formatMessage({ id: "formName" })}
                          required="required"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="formGroup">
                        <input
                          id="form_email"
                          type="email"
                          name="email"
                          placeholder={intl.formatMessage({ id: "formEmail" })}
                          required="required"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="formGroup">
                        <input
                          id="form_phone"
                          type="text"
                          name="phone"
                          placeholder={intl.formatMessage({ id: "formPhone" })}
                          required="required"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="formGroup">
                        <textarea
                          id="form_message"
                          name="message"
                          placeholder={intl.formatMessage({
                            id: "formMessage",
                          })}
                          rows="4"
                          required="required"
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <div className="buttonWrapper">
                        <button type="submit">
                          {intl.formatMessage({ id: "formSubmit" })}
                        </button>
                      </div>
                    </div>
                  </form>
                  {/* ConteactForm to purchasing@tsbsteel.com */}
                  <form
                    name="ContactFormPurchasing"
                    method="POST"
                    data-netlify="true"
                    onSubmit={handleSubmit}
                    className={showForm3 ? "" : "hidden"}
                  >
                    <input
                      type="hidden"
                      name="form-name"
                      value="ContactPurchasing"
                    />
                    <input
                      type="hidden"
                      name="subject"
                      value={state.name + " has been submitted form."}
                    />
                    <p hidden>
                      <label>
                        Don’t fill this out:
                        <input name="bot-field" onChange={handleChange} />
                      </label>
                    </p>

                    <div className="controls">
                      <div className="formGroup">
                        <input
                          id="form_name"
                          type="text"
                          name="name"
                          placeholder={intl.formatMessage({ id: "formName" })}
                          required="required"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="formGroup">
                        <input
                          id="form_email"
                          type="email"
                          name="email"
                          placeholder={intl.formatMessage({ id: "formEmail" })}
                          required="required"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="formGroup">
                        <input
                          id="form_phone"
                          type="text"
                          name="phone"
                          placeholder={intl.formatMessage({ id: "formPhone" })}
                          required="required"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="formGroup">
                        <textarea
                          id="form_message"
                          name="message"
                          placeholder={intl.formatMessage({
                            id: "formMessage",
                          })}
                          rows="4"
                          required="required"
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <div className="buttonWrapper">
                        <button type="submit">
                          {intl.formatMessage({ id: "formSubmit" })}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </ContactContainer>
        </div>
      </PageContainer>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query ContactUsPageQuery($locale: String) {
    pageFields: allContentfulContactPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          seoTitle
          seoDescription
          coverImage {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          pageTitle
          introContent {
            introContent
          }
        }
      }
    }
  }
`

export default injectIntl(ContactUsPage)
